<template>

  <div id="page-products">
    <div v-if="screenType === 'desktop'">
      <v-container>
        <div v-html="products" style="text-align: left"></div>
      </v-container>
    </div>
    <div v-else>
      <div v-html="products" style="text-align: left"></div>
    </div>
  </div>

</template>

<script>
export default {
  name: "Help",
  data() {
    return {
      screenType: null,
      products: null
    }
  },
  mounted() {
    this.screenType = window.screen.width < 500 ? 'mobile' : window.screen.width < 1000 ? 'tablet' : 'desktop'
    window.axios
        .get('api/setting/products')
        .then((res) => {
          this.products = res.data.data
        })

  }
}
</script>

<style scoped>

</style>